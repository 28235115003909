/*-----------------------------
Style Variables
---
To access, wrap a page in the Layout component and call the props within a styled component.
I.E props => props.theme.color.
-----------------------------*/

export default {
  color: {
    green: "#546a5b",
    burgundy: "#58171f",
    yellow: "#ffb85e",
    pink: "#ff9999",
    white: "#ffffff",
  },
}
