import { createGlobalStyle, css } from "styled-components"
import { mobileSize, desktopSize } from "../helpers/sizeCalculations"

/*-----------------------------
Fonts
---
Font size is based on viewport width. We want the font to match the mockups exactly at 1440px. The calculation is ({font-size-at-1440} / 1440) * 100.
Line Height is set on a per tag basis and is a factor of the base font size and line height for that tag. I.E Line divided by Size.
-----------------------------*/

const baseFontStyles = css`
  font-weight: normal;
  color: inherit;
  margin: 0 0 20px;
`

const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.theme.color.burgundy};
    font-size: ${desktopSize(18)};
    font-family: "gt-walsheim-regular";
    -webkit-overflow-scrolling: touch;
  }

  p {
    ${baseFontStyles}
    font-size: ${desktopSize(20)};
    line-height: 1.2;
    font-family: "gt-walsheim-regular";
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(18)};
    }
    i,em {
      font-family: "gt-walsheim-regular-oblique";
    }
  }

  .small-body p {
    ${baseFontStyles}
    font-size: ${desktopSize(18)};
    line-height: 1.22;
    font-family: "gt-walsheim-regular";
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(18)};
    }
  }

  h1 {
    ${baseFontStyles}
    font-size: ${desktopSize(130)};
    line-height: 0.85;
    font-family: "gt-sectra-fine-book", serif;
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(50)};
      line-height: .9;
    }
  }

  h2 {
    ${baseFontStyles}
    font-size: ${desktopSize(50)};
    line-height: 1.1;
    font-family: "gt-sectra-fine-book", serif;
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(40)};
      line-height: 1.125;
    }
  }

  h3 {
    ${baseFontStyles}
    font-size: ${desktopSize(40)};
    line-height: 1;
    font-family: "gt-sectra-fine-regular", serif;
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(30)};
    }
  }

  h4 {
    ${baseFontStyles}
    font-size: ${desktopSize(30)};
    line-height: 1.33;
    font-family: "gt-sectra-fine-regular", serif;
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(25)};
      line-height: 1.2;
    }
  }

  h5 {
    ${baseFontStyles}
    font-size: ${desktopSize(19)};
    line-height: 1;
    letter-spacing: ${desktopSize(0.59)};
    text-transform: uppercase;
    font-family: "gt-walsheim-regular";
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(19)};
      letter-spacing: ${mobileSize(0.59)};
    }
  }

  h6 {
    ${baseFontStyles}
    font-size: ${desktopSize(23)};
    line-height: 1.22;
    font-family: "gt-walsheim-regular";
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(23)};
    }
  }

  .quote {
    ${baseFontStyles}
    font-size: ${desktopSize(40)};
    line-height: 1.13;
    font-family: "gt-sectra-fine-book", serif;
    @media all and (max-width: 768px) {
      font-size: ${mobileSize(30)};
      line-height: 1.16;
    }
  }

  a {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .wysiwyg {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      text-decoration: underline;
    }
  }

  .gatsby-image-wrapper > img {
    @supports (-ms-ime-align: auto) {
      transition-delay: 1s !important;
    }
  }

  /*Common Styling for the cookie policy templates.*/
  .cookie-template-subtitle {
    h2 {
      padding: ${desktopSize(319)} 0 ${desktopSize(267)};
      margin: 0 auto;

      @media (max-width: 768px) {
        width: 100%;
        padding: ${mobileSize(243)} 0 ${mobileSize(267)};
        margin: -7px 0 auto;
      }

      br {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .cookie-template-content {
    @media (max-width: 768px) {
      width: 92%;
    }

    li {
      font-size: ${desktopSize(20)};
      line-height: 1.2;
      font-family: "gt-walsheim-regular";
      @media all and (max-width: 768px) {
        font-size: ${mobileSize(18)};
      }
    }
  }

  .main-site-logo {
    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      bottom : 60px;
    }
  } 

  ${typeof window !== "undefined" &&
    window.navigator.userAgent.match(/iPad/i) &&
    `
    .ipad {
      ol, ul {
        @media screen and (max-width: 768px) {
          padding-left: 1.2rem;
        }
      }
    }
  `}

  .ipad {
    a {
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

`

export default GlobalStyle
