/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"
import theme from "../styles/LesTheme"
import Transition from "../components/global/Transition"
import "../styles/css/fonts.css"
import GlobalStyle from "../styles/GlobalStyle"
import ReactGA from "react-ga"

process.env.GATSBY_UA_CODE && ReactGA.initialize(process.env.GATSBY_UA_CODE)

class Layout extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render() {
    let { children, location } = this.props
    return (
      <ThemeProvider theme={theme}>
        <>
          <GlobalStyle />
          <Transition location={location}>{children}</Transition>
        </>
      </ThemeProvider>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
