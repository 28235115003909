import React from "react"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

/*-----------------------------
Transition Component
---
Wraps each page via our layout. Here is where we set the enter/exit duration of all page animations and 
pass the animation status (enter, entering, exiting, exit) down as a prop to the page components. The prop can be used to trigger a gsap animation 
in componentDidUpdate or pass the prop through to a styled component.

Transition Flow When Switching Routes
---
1. Current page elements transition out in 1.5s. 
2. Incoming page enters the dom immediately. It's positioned absolute and elements within it are outside the viewport
or the full page wrapper is opacity 0. The incoming page waits 2.5s to transition in.
3. React transition group removes the previously current page from the DOM.
-----------------------------*/

const animationDuration = 3000

class Transition extends React.PureComponent {
  render() {
    const { children, location } = this.props
    return (
      <TransitionGroup>
        <ReactTransition key={location.pathname} timeout={animationDuration}>
          {animationStatus =>
            React.Children.map(children, child =>
              React.cloneElement(child, {
                animationStatus,
                className: animationStatus,
              })
            )
          }
        </ReactTransition>
      </TransitionGroup>
    )
  }
}

export default Transition
