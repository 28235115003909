// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-js": () => import("../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-current-investments-js": () => import("../src/pages/current-investments.js" /* webpackChunkName: "component---src-pages-current-investments-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-larry-ellisons-story-js": () => import("../src/pages/larry-ellisons-story.js" /* webpackChunkName: "component---src-pages-larry-ellisons-story-js" */),
  "component---src-pages-previous-investments-js": () => import("../src/pages/previous-investments.js" /* webpackChunkName: "component---src-pages-previous-investments-js" */),
  "component---src-pages-privacy-policy-js": () => import("../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-use-js": () => import("../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-test-js": () => import("../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

