/*-----------------------------
Size Calculations
---
Calculate viewport width size based on pixel units.
-----------------------------*/

// Mobile

export function mobileSize(pixels) {
  return `${(pixels / 375) * 100}vw`
}

// Desktop

export function desktopSize(pixels) {
  return `${(pixels / 1440) * 100}vw`
}
